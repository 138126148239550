import {
  Button,
  Container,
  Flex,
  HeadingDisplay,
  HeadingSubline,
} from '@hausgold/designsystem';
import Page from 'app-components/Page';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';

const ForOFour = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('404.title')}>
      <Flex
        direction="column"
        bg="blue.400"
        color="white"
        minH="100vh"
        justify="center"
      >
        <Container textAlign="center">
          <HeadingDisplay>{t('404.headline')}</HeadingDisplay>
          <HeadingSubline size="xl" color="inherit">
            {t('404.text')}
          </HeadingSubline>
          <Link href="/" passHref>
            <Button as="a" variant="inverted">
              {t('404.button')}
            </Button>
          </Link>
        </Container>
      </Flex>
    </Page>
  );
};

export default ForOFour;
